import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';
import myImage from '../assets/portfolioimg.png'; 

const Home = () => {
  return (
    <div name='home' className=' w-full h-full bg-[#0a192f] md: pt-10'>
      {/* Container */}
      <div className='sm:max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        {/* Flex container for image and text */}
        <div className='flex flex-col-reverse sm:flex-row items-center'>
          {/* Text Content */}
          <div className='text-center sm:text-left'>
            <p className='text-pink-600'>Hi, my name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
              djihane 
            </h1>
            <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
              I'm a Frontend Developer.
            </h2>
            <p className='text-[#8892b0] py-4 max-w-[700px] mx-auto sm:mx-0'>
              I’m a frontend developer with a focus on building responsive websites that provide seamless user experiences. I'm also a CMS developer who specializes in creating eCommerce websites.
            </p>
            <div>
            <Link to='work' smooth={true} duration={500}>
              <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
                View Work
                <span className='group-hover:rotate-90 duration-300'>
                  <HiArrowNarrowRight className='ml-3' />
                </span>
              </button>
              </Link>
            </div>
          </div>

          {/* Image */}
          <img 
            src={myImage} 
            alt='My Portfolio Image' 
            className='w-64 h-auto pt-20 sm:ml-16 md:mb-24 w-72 ' 
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
