import marble from "../assets/projects/marbleproject.png";
import travel from "../assets/projects/travel.png";
import ecommercexebsite from "../assets/projects/ecommercexebsite.png";
import frimousse from "../assets/projects/frimousse.png";
export const data = [
  {
    id: 1,
    name: "React JS Application",
    image: marble,
    github: "https://github.com/djihane05/react-marble.git",
    live: "https://djihane05.github.io/react-marble/",
  },
  {
    id: 2,
    name: "React JS Application",
    image: travel,
    github: "https://github.com/djihane05/react-travel.git",
    live: "https://djihane05.github.io/react-travel/",
  },
  {
    id: 3,
    name: "created with wordpress",
    image: ecommercexebsite,
    github: "",
    live: "https://djhmin.net/",
  },
  {
    id: 4,
    name: "created with wordpress",
    image: frimousse,
    github: "",
    live: "https://frimousse.shop/",
  },
];
